import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ctecs, ctecsOrange } from "../../assets/images/logos";

const Header = () => {
  const [isNavOpen, setNavOpen] = useState(false);
  const [logoSrc, setLogoSrc] = useState(ctecs);

  const toggleNav = () => {
    setNavOpen(!isNavOpen);
  };

  const handleLogoHover = () => {
    setLogoSrc(ctecsOrange);
  };

  const handleLogoLeave = () => {
    setLogoSrc(ctecs);
  };

  useEffect(() => {
    const closeMobileNav = (event) => {
      if (isNavOpen && !event.target.closest(".nav-links-mobile")) {
        setNavOpen(false);
      }
    };

    setTimeout(() => {
      if (isNavOpen) {
        window.addEventListener("click", closeMobileNav);
      }
    }, 1);

    return () => {
      window.removeEventListener("click", closeMobileNav);
    };
  }, [isNavOpen]);

  return (
    <nav role="navigation">
      <header className="header" id="header">
        <Link className="logo" to="/" title="CTECS Logo">
          <img
            src={logoSrc}
            alt="Logo"
            onMouseOver={handleLogoHover}
            onMouseLeave={handleLogoLeave}
            onFocus={handleLogoHover}
            title="Logo Image"
            loading="lazy"
          />
        </Link>
        <div
          className={`nav-toggle ${isNavOpen ? "open" : ""}`}
          onClick={toggleNav}
          title="Open Navigation Menu"
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <ul className={`nav-links`}>
          <li className="Link wrapper">
            <Link to="/" lang="en" title="Go to Home Page">
              Home
            </Link>
          </li>
          <li className="Link wrapper">
            <Link to="/about" lang="en" title="Go to About Page">
              About
            </Link>
          </li>
          <li className="Link wrapper">
            <Link to="/contact" lang="en" title="Go to Contact Page">
              Contact
            </Link>
          </li>
        </ul>
        <ul className={`nav-links-mobile ${isNavOpen ? "open" : ""}`} lang="en">
          <li className="Link wrapper">
            <Link to="/" lang="en" title="Go to Home Page">
              Home
            </Link>
          </li>
          <li className="Link wrapper">
            <Link to="/about" lang="en" title="Go to About Page">
              About
            </Link>
          </li>
          <li className="Link wrapper">
            <Link
              to="/parents-students"
              lang="en"
              title="Go to Parents & Students Page"
            >
              Parents & Students
            </Link>
          </li>
          <li className="Link wrapper">
            <Link to="/contact" lang="en" title="Go to Contact Page">
              Contact
            </Link>
          </li>
          <li className="Link wrapper">
            <Link to="/news" lang="en" title="Go to News Page">
              News
            </Link>
          </li>
          <li className="Link wrapper">
            <Link to="/admissions" lang="en" title="Go to Admissions Page">
              Admissions
            </Link>
          </li>
        </ul>
      </header>
    </nav>
  );
};

export default Header;
