import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";

const Layout = () => {
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  useEffect(() => {
    const hasAgreed = localStorage.getItem("hasAgreed");
    if (hasAgreed) {
      setShowDisclaimer(false);
    }
  }, []);

  const handleAgree = () => {
    localStorage.setItem("hasAgreed", "true");
    setShowDisclaimer(false);
  };

  return (
    <main lang="en" role="main" aria-roledescription="Page content container">
      <div className="container">
        {showDisclaimer && (
          <div className="disclaimer-modal">
            <div className="modal-background">
              <p>
                This is a fake website, I am working on this for a personal
                project, this is NOT the real CTECS website.
                <br />
                <br />
                <a href="https://cttech.org" title="Go to cttech.org">
                  Here
                </a>{" "}
                is the real one.
              </p>
              <button
                onClick={handleAgree}
                title="Provide agreement of understanding"
              >
                I Understand
              </button>
            </div>
          </div>
        )}
        <Header />
        <Outlet />
      </div>
    </main>
  );
};

export default Layout;
