import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "../../styles/scss/global/global.scss";
import * as Global from "./global.router";
import * as Pages from "./pages.router";
import * as Misc from "./misc.router";

import { Snooping } from "./modules";

const { Images } = Misc;
const { Layout, Loading, NoPage } = Global;
const { Home, About } = Pages;

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <Suspense fallback={<Loading />}>
                <Home />
              </Suspense>
            }
          />
          <Route path="about">
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <About />
                </Suspense>
              }
            />
          </Route>
          <Route path="links">
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <>Links</>
                </Suspense>
              }
            />
          </Route>
          <Route path="uniforms">
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <>Uniforms</>
                </Suspense>
              }
            />
          </Route>
          <Route path="jobs">
            <Route
              index
              element={
                <Suspense fallback={<Loading />}>
                  <>Jobs</>
                </Suspense>
              }
            />
          </Route>
          <Route path="dev">
            <Route path="preview">
              <Route path="loading" element={<Loading />} />
            </Route>
          </Route>
          <Route path="*" element={<NoPage />} />
        </Route>
        <Route path="wp-json">
          <Route
            index
            element={
              <Snooping />
            }
          />
        </Route>
        <Route path="copyright">
          <Route path="credit">
            <Route path="images" element={<Images />} />
          </Route>
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
