import React from "react";

const Snooping = () => {
  return (
    <main>
      <div
        className="container"
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <span
          style={{
            fontSize: "20em",
            fontWeight: "800",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            pointerEvents: "none",
            userSelect: "none",
          }}
        >
          :3
        </span>
        <div className="page">
          <div className="wrapper">
            Trying to hack us? Please don't{" "}
            <span style={{ fontSize: "2em", fontWeight: "800" }}>:(</span>
            <p
              style={{
                padding: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              We do not like snoops{" "}
              <span
                style={{
                  fontSize: "1em",
                  fontWeight: "800",
                  marginTop: "1em",
                }}
              >
                :3
              </span>
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Snooping;
